<template>
    <v-app>



        <achievementListView :pAchievementList="this.$store.state.achievementList" :should-init="true" @close="showAchievements=false"/>


        <v-footer app>
            <!-- -->

            <v-row>
                <v-col align="right">
                    <router-link  style="text-decoration: none; color: inherit;"
                                  to='/'>
                        <v-icon
                                color=red>
                            mdi-exit-to-app
                        </v-icon>
                    </router-link>
                </v-col>
            </v-row>


        </v-footer>
    </v-app>
</template>

<script>

    //import iconGridSelection from './components/IconGridSelection.vue'
    import achievementVue from './components/AchivementList.vue';

    export default {
        components: {
            //'iconGridSelection': iconGridSelection,
            'achievementListView':achievementVue
        },

        props:["pSkillList","pIconList","pAchievementList"],
        data () {

            //console.log('ACHIEVEMENT PATH ' + this.pAchievementList);
            return {
                iconList: [],
                supportColor: 'green',
                show: false,
            }
        },

        methods: {
            doCloseSelection :function() {

            },
            changeColor: function() {

            },
            onUpdatedNewIcon: function() {

            }
        }
    }

</script>
