<template>

    <v-card
            min-height="600"
            max-height="600"
    >

        <v-card-title>
           Achievement List

        </v-card-title>
        <v-dialog
            v-model="showSimulation"
            scrollable
            @click:outside="close()"
        >
            <AchievementSimulation v-if="showSimulation" :pAchievementList="this.pAchievementList" @close="close()"/>
        </v-dialog>




        <v-dialog
                v-model="showDetail"
                max-width="900px"
                scrollable
                @click:outside="close()"
        >
            <v-card>
                <v-card-title>{{this.$get(selectedRow,'name')}} - Will cumulate {{detailAchievement.totalReward}} diamond</v-card-title>
                <v-data-table

                        :headers="headersDetail"
                        :items="detailAchievement.list"

                        class="elevation-1 petList"

                        hide-default-footer

                        :options="{itemsPerPage:200}"
                        :search="search"
                        show-group-by
                >


                </v-data-table>
            </v-card>
        </v-dialog>


        <v-card
                max-height="600" class="overflow-y-auto"
        >
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table

                                :headers="headers"
                                :items="achievementList"

                                class="elevation-1 petList"

                                hide-default-footer

                                :options="{itemsPerPage:200}"
                                :search="search"
                                show-group-by
                                @click:row="doClickOnRow"
                        >



                            <template v-slot:item.baseGoal="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.baseGoal"
                                >

                                    {{ item.baseGoal }}

                                    <template v-slot:input>
                                        <v-text-field
                                                v-model="item.baseGoal"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>

                            </template>


                            <template v-slot:item.jumpingLevel="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.jumpingLevel"
                                >

                                    {{ item.jumpingLevel }}

                                    <template v-slot:input>
                                        <v-text-field
                                                v-model="item.jumpingLevel"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>

                            </template>

                            <template v-slot:item.cumulation="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.cumulation"
                                        @save="save"
                                        large
                                        persistent
                                >

                                    {{ item.cumulation }}

                                    <template v-slot:input>
                                        <v-checkbox
                                                v-model="item.cumulation"
                                                :label="`${item.cumulation.toString()}`"
                                        ></v-checkbox>
                                        <!--                                <v-text-field-->
                                        <!--                                        v-model="item.cumulation"-->
                                        <!--                                        label="Edit"-->
                                        <!--                                        single-line-->
                                        <!--                                        autofocus-->
                                        <!--                                        @focus="$event.target.select()"-->

                                        <!--                                ></v-text-field>-->
                                    </template>
                                </v-edit-dialog>

                            </template>

                        </v-data-table>


                    </v-col>

                </v-row>
            </v-container>



    </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
            <v-btn color="green darken-1" text @click="dispatchSave()">Save</v-btn>
            <v-btn color="green darken" text @click="openSimulation()">SIMULATE</v-btn>
        </v-card-actions>
    </v-card>



</template>





<style lang="scss">
    .petList tbody {
        tr .standard:hover {
            background-color: darkgray !important;
        }
    }

    .standard {
        background-color: transparent;
    }

    .selected {
        background-color: dodgerblue !important;
    }

</style>

<script>

    import lodash from 'lodash';
    import GameplayAchievement from '../GameplayAchievement';
    import AchievementConstant from '../constants/AchievementIdConstants';
    import AchievementSimulation from "@/achievement-simulation/screen/AchievementSimulation.vue";

    export default {
        data() {
            return {
                showSimulation: false,
                showDynamic: true,
                selectedRow: null,
                search: "",
                achievementList: [],
                showDetail: false,
                cumulatedReward: 0,
                headers: [
                    {text: 'ID', value: 'id', groupable: false},
                    {text: 'Achievement', value: 'name', groupable: false},
                    {text: 'Basegoal', value: 'baseGoal', groupable: false},
                    {text: 'Unit', value: 'unit', groupable: false},
                    {text: 'Mode', value: 'mode', groupable: false},
                    {text: 'Jumping', value: 'jumpingLevel', groupable: false},
                    {text: 'Formula', value: 'formula', groupable: false},
                    {text: 'Cumulation', value:'cumulation', groupable: true}

                ],
                headersDetail: [
                    {text: 'Level', value: 'level', groupable: false},
                    {text: 'Challenge', value: 'challenge', groupable: false},
                    {text: 'Reward', value: 'reward', groupable: false}
                ]
            }
        },

        props: {
            'pAchievementList': {
                type: Array,
                required: true
            },
            'shouldInit': {
                type: Boolean,
            }
        },

        methods: {


            getSelected: function (index) {
                return index === this.selectedRow;
            },

            didClickOnRow: function (index) {
                //console.log('did click on row');
                //console.log(index);
                this.selectedRow = index;
            },

            loadData: function (list) {
                this.achievementList = [];
                if (Array.isArray(list)) {
                    list.forEach(e => {
                        //console.log('load data')
                        //let copy = deepCopy(e);

                        //this.questList.push(Object.assign({}, e));

                        //let achievementModel = new Achievment(e);
                        let achievement = new GameplayAchievement(e);

                        this.achievementList.push(lodash.cloneDeep(achievement));
                        //this.questList.push(copy);
                    });
                }
            },

            save : function(e) {
                //console.log(e)
                return e;
            },


            didChange: function () {
                //console.log('DID CHANGE did change something in AchievementList');
                this.loadData(this.pAchievementList);
            },

            dispatchClose: function () {
                this.$emit('close');
            },

            dispatchSave: async function () {
                //this.questList;
                await this.$store.commit('pushAchievements',this.achievementList);
            },
            openSimulation: async function () {
                this.showSimulation = true;
            },

            didUpdateAchievementList: function (list) {
                //console.log('did update achievement list');
                console.log(list);

                //this.$store.commit('updateAchievement',this.achievementList)
            },

            doClickOnRow : function(item,slot) {
                //console.log('DO CLICK ON ROW');
                //console.log(item);
                console.log(slot);
                this.selectedRow = item;
                this.showDetail = true;
            },

            close : function() {
                this.showDetail = false;
                this.showSimulation = false;
            }


        },
        computed : {
            detailAchievement : function() {

                //console.log('inside comuted property detail Achievement');
                //console.log(this.selectedRow);


                //let achievement = this.achievementList[this.selectedRow];
                ////console.log(this.achievementList);
                ////console.log(achievement);

                let achievement = this.selectedRow;

                let totalCompletions = 30;


                //let levelMatchingCompletion = 10;
                let mapping = [];
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                //this.cumulatedReward = 0;
                let cumulatedReward = 0;
                if (achievement) {
                    achievement.reset();

                    if (achievement.id === AchievementConstant.UNLOCK_HEROES) {
                        totalCompletions = 9;
                    }

                    if (achievement.id === AchievementConstant.UNLOCK_SUPPORT){
                        totalCompletions = 17;
                    }

                    for (let nthComplete=1;nthComplete<totalCompletions;nthComplete++) {


                        let content = {};
                        content.level = achievement.currentLevel;
                        content.reward = achievement.getDiamondsForAchievementColletMoney();
                        content.challenge = achievement.challenge;

                        if (achievement.id === AchievementConstant.COLLECT_GOLD
                        || achievement.id === AchievementConstant.REACH_HEROES_DPS
                        ) {
                            content.challenge = achievement.challenge.ToReadableAlphabetV2();
                        }
                        mapping.push(content);

                        cumulatedReward = cumulatedReward + content.reward;

                        if (nthComplete > 0) {
                            achievement.collectAchievement();
                        }
                    }
                    achievement.reset();
                }

                console.log("cumulatedReward : " + cumulatedReward);

                return {list:mapping,totalReward:cumulatedReward};
            }
        },

        created: function () {
            this.loadData(this.pAchievementList);
        },

        components: {AchievementSimulation},

        watch: {
            pAchievementList: {
                deep: true,
                handler: 'didChange'
            },

            shouldInit: {
                handler: 'didChange'
            },

            achievementList: {
                deep: true,
                handler: 'didUpdateAchievementList'
            }
        },

    }

</script>
